<template>
	<div class="my-home-page">
		<div class="info clearfix">
			<div class="left">
				<div class="info-left-row">
					<img src="../../assets/img/man.png">
					<div class="left-info">
						<div class="title">
							<span class="line1" :title="userInfo.nick_name">{{ userInfo.nick_name }}</span>
							<div class="vip" v-if="userInfo.grade_id > 0 && userInfo.grade">
								<img src="../../assets/img/vip01.png">
								<em>{{ userInfo.grade.name }}</em>
							</div>
						</div>
						<div class="left-info-row">
							<span class="phone">{{ userInfo.mobile }}</span>
							<el-button class="edit" size="mini" type="warning" @click="handleNickname">详细信息补充</el-button>
						</div>
					</div>
				</div>
				<router-link to="/myhome/myaddress">
					<div class="add">管理货地址<i class="el-icon-arrow-right"></i></div>
				</router-link>
			</div>
			<div class="right">
				<div class="title">我的资产</div>
				<ul class="class">
					<li>
						<router-link to="/myhome/wallet">
							<span>{{ assets.balance }}</span>
							<em>账户余额</em>
						</router-link>
					</li>
					<li>
						<router-link to="/myhome/integral">
							<span>{{ assets.points }}</span>
							<em>积分</em>
						</router-link>
					</li>
					<!-- <li>
						<router-link to="/myhome/mycoupon">
							<span>{{ assets.coupon }}</span>
							<em>优惠券</em>
						</router-link>
					</li> -->
				</ul>
			</div>
		</div>
		<div class="my-order">
			<div class="title">我的订单</div>
			<ul class="classify clearfix">
				<router-link to="/myhome/myorder?dataType=payment">
					<li>
						<i class="iconfont">&#xe706;
							<em class="dot" v-if="todoCounts.payment > 0">{{ todoCounts.payment }}</em>
						</i>
						<span>待付款</span>
					</li>
				</router-link>
				<router-link to="/myhome/myorder?dataType=received">
					<li>
						<i class="iconfont">&#xe771;
							<em class="dot" v-if="todoCounts.received > 0">{{ todoCounts.received }}</em></i>
						<span>待收货</span>
					</li>
				</router-link>
				<router-link to="/myhome/myorder?dataType=comment">
					<li>
						<i class="iconfont">&#xe633;
							<em class="dot" v-if="todoCounts2.comment > 0">{{ todoCounts2.comment }}</em>
						</i>
						<span>待评价</span>

					</li>
				</router-link>
				<router-link to="/myhome/returnlist">
					<li>
						<i class="iconfont">&#xe629;
							<em class="dot" v-if="todoCounts2.refund > 0">{{ todoCounts2.refund }}</em>
						</i>
						<span>退换/售后</span>
					</li>
				</router-link>
				<router-link to="/myhome/myorder?dataType=all">
					<li>
						<i class="iconfont">&#xe63f;</i>
						<span>全部订单</span>
					</li>
				</router-link>
			</ul>
		</div>
		<div class="product-list buying">
			<div class="title">大家都在买</div>
			<!-- 产品 -->
			<div class="recommend-list clearfix">
				<el-empty :image-size="50" description="暂无产品" v-if="recommendList.length==0"></el-empty>
				<el-row :gutter="30">
					<el-col :span="4" v-for="(item,index) in recommendList" :key="index" class="recommend-list-item">
						<router-link :to="{ path: '/details', query: { id: item.goods_id }}">
							<div class="img">
								<img :src="item.goods_image" />
							</div>
							<p class="line1"><span>备件号：</span>{{item.goods_no}}</p>
							<p class="line2"><span>名称：</span>{{item.goods_name}}</p>
							<!-- <p><span>适用车型：</span>{{item.type}}</p> -->
							<p class="p-bottom">
								<span class="price">￥{{item.goods_price_min}}</span>
								<!-- <em class="cart-icon" :class="[{'active':item.cartSelect},{'shake':item.cartSelect}]"
									@click.prevent="addCart(item,index)">
									<i class="iconfont cart">&#xe604;</i>
									<i class="iconfont select" v-if="item.cartSelect">&#xe6b2;</i>
								</em> -->
							</p>
						</router-link>
					</el-col>
				</el-row>
			</div>
		</div>
		<el-dialog title="详细信息补充" :visible.sync="dialogVisible" width="30%">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="公司名称">
					<el-input v-model="userInfo.nick_name"></el-input>
				</el-form-item>
				<el-form-item label="公司地址">
					<el-input v-model="userInfo.fac_address"></el-input>
				</el-form-item>
				<el-form-item label="联系人">
					<el-input v-model="userInfo.fac_contact"></el-input>
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input v-model="userInfo.fac_phone"></el-input>
				</el-form-item>
        <el-form-item label="税号">
          <el-input v-model="userInfo.tax_code"></el-input>
        </el-form-item>
        <el-form-item label="开户银行">
          <el-input v-model="userInfo.bank_name"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号">
          <el-input v-model="userInfo.account_no"></el-input>
        </el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmitNickname">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import * as UserApi from '@/api/user'
	import * as OrderApi from '@/api/order'
	import * as SettingApi from '@/api/setting'
	import * as RefundApi from '@/api/refund'
	import * as PagesApi from '@/api/page'
	export default {
		data() {
			return {
				// 系统设置
				setting: {},
				// 当前用户信息
				userInfo: {},
				// 账户资产
				assets: {
					balance: '--',
					points: '--',
					coupon: '--'
				},
				// 当前用户待处理的订单数量
				todoCounts: {
					payment: 0,
					deliver: 0,
					received: 0
				},
				todoCounts2: {
					comment: 0,
					refund: 0
				},
				recommendList: [],
				form: {},
				dialogVisible: false
			}
		},
		created() {
			this.getUserInfo()
			this.getSetting()
			this.getTodoCount()
			this.getAsset()
			this.getRecommendList()
		},
		methods: {
			getRecommendList() {
				let app = this
				PagesApi.detail(0).then(response => {
					const {
						data: {
							pageData
						}
					} = response
					pageData.items.forEach(item => {
						if (item.name == '商品组') {
							app.recommendList = item.data.slice(0, 6)
						}
					})
				})
			},
			go(url) {
				this.$router.push(url)
			},
			getUserInfo() {
				let app = this
				UserApi.info()
					.then(result => {
						app.userInfo = result.data.userInfo
					})
			},
			getSetting() {
				let app = this
				SettingApi.data()
					.then(result => {
						app.setting = result.data.setting
					})
			},
			getTodoCount() {
				let app = this
				OrderApi.todoCounts()
					.then(result => {
						app.todoCounts = result.data.counts
					})
				OrderApi.list({
						dataType: 'comment',
						page: 1
					}, {
						load: false
					})
					.then(result => {
						app.todoCounts2.comment = result.data.list.total
					})
				RefundApi.list({
						state: -1,
						page: 1
					}, {
						load: false
					})
					.then(result => {
						// 合并新数据
						app.todoCounts2.refund = result.data.list.total
					})
			},
			getAsset() {
				let app = this
				UserApi.assets()
					.then(result => {
						app.assets = result.data.assets
					})
			},
			handleNickname() {
				this.dialogVisible = true
			},
            onSubmitNickname() {
                UserApi.updatename({
                    nick_name: this.userInfo.nick_name,
                    fac_address: this.userInfo.fac_address,
                    fac_contact: this.userInfo.fac_contact,
                    fac_phone: this.userInfo.fac_phone,
                    tax_code: this.userInfo.tax_code,
                    bank_name: this.userInfo.bank_name,
                    account_no: this.userInfo.account_no
                })
                    .then(result => {
                        this.$message.success('修改成功')
                        this.dialogVisible = false
                    })
            }
		}
	}
</script>

<style lang='scss'>
	@import "../../assets/css/product-list.scss";
</style>
